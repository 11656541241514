import React from 'react';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import { StaticQuery, graphql } from 'gatsby';

// fonts
import 'typeface-lato';
import 'typeface-raleway';

// IE polyfill
import 'string.prototype.startswith';

import { rhythm } from '../utils/typography';
import globalStyle from '../style/global-style.scss';

import { palette } from '../style/utils';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import BreakpointWrapper from '../components/BreakpointWrapper/BreakpointWrapper';
import layoutStyle from './indexLayout.module.scss';

export const BackgroundImagesContext = React.createContext();

const layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query wrapperImages {
        backgrounds: allImageSharp(
          filter: { fluid: { originalName: { regex: "/wrapper*/" } } }
        ) {
          edges {
            node {
              fluid(maxWidth: 5000, quality: 95) {
                name: originalName
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `}
    render={data => (
      <BreakpointWrapper>
        <Helmet
          title="Pixelnebula Flare Digital Signage"
          meta={[
            {
              name: 'description',
              content:
                'Flare digital signage from Pixelnebula - simple to use, beautiful to look at.',
            },
            {
              name: 'keywords',
              content:
                'digital signage, digital screens, simple digital display management, flare, pixelnebula',
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=0.75, user-scalable=1',
            },
          ]}
        >
          <style type="text/css" href={globalStyle} />
          <style type="text/css">{`:root { --rhythm-unit: ${rhythm(
            1
          )}; }`}</style>
        </Helmet>
        <Header />
        <BackgroundImagesContext.Provider value={data.backgrounds}>
          <div className={layoutStyle.contentWrapper}>{children}</div>
        </BackgroundImagesContext.Provider>
        <Footer />
        <CookieConsent
          location="bottom"
          style={{}}
          buttonStyle={{
            marginTop: 0,
            backgroundColor: palette.blue,
            color: palette.white,
          }}
        >
          We use cookies on this website for visitor analytics and for our live
          chat system.
        </CookieConsent>
      </BreakpointWrapper>
    )}
  />
);

export default layout;