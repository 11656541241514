import React from 'react';
import styles from './MenuButton.module.scss';
import { combine, bg } from '../../style/utils';

const MenuButton = ({ menuOpen, onClick, className }) => (
  <div
    className={combine(
      className,
      styles.container,
      menuOpen ? combine(styles.open, bg.purple) : bg.transparent
    )}
    onClick={onClick}
  >
    <div className={styles.wrapper}>
      <span className={combine(styles.bar, menuOpen ? bg.white : bg.purple)} />
      <span className={combine(styles.bar, menuOpen ? bg.white : bg.purple)} />
      <span className={combine(styles.bar, menuOpen ? bg.white : bg.purple)} />
    </div>
  </div>
);

export default MenuButton;
