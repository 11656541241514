import styletron from 'gatsby-plugin-styletron';
const css = styletron().css;

const palette = {
  blue: '#30649b',
  green: '#019d96',
  purple: '#64258e',
  darkerPurple: '#4e016b',
  white: '#ffffff',
  lightGrey: '#ababab', // social media button text
  grey: '#697a7f', // Used in HeroSpace subtitle.
  footerHeaderGrey: '#dedde0', // Copyright and footer tiny links
  footerGrey: '#989898', // Copyright and footer tiny links
  darkGrey: '#444444', // default header colour
  blueGrey: '#72797b', // used in p copy on lightblue background, e.g. features
  lightBlue: '#d4ecfa',
  darkPurple: '#170229',
  transparent: 'transparent',
};

function combine(...styles) {
  return styles.filter(style => !!style).join(' ');
}

function smallerThan(breakpoint, cssProps) {
  return css({
    [`@media (max-width: ${breakpoint})`]: cssProps,
  });
}

const styles = {};

// makes a css class for each of the properties in here which is exported
// under the key listed here, and with a value of an object with palette colour
// names as the properties. Values of those properties are the generated classes
const outputMap = {
  bg: 'backgroundColor',
  txt: 'color',
  border: 'borderColor',
};

Object.keys(outputMap).forEach(key => {
  let prop = outputMap[key];
  styles[key] = {};
  Object.keys(palette).forEach(colour => {
    styles[key][colour] = css({
      [prop]: palette[colour],
    });
  });
});

styles.txt.bold = css({ fontWeight: 600 });
styles.txt.italic = css({ fontStyle: 'italic' });
styles.txt.lighter = css({ fontWeight: 200 });
styles.txt.light = css({ fontWeight: 300 });
styles.txt.normal = css({ fontWeight: 400 });
styles.txt.center = css({ textAlign: 'center' });
styles.txt.left = css({ textAlign: 'left' });
styles.txt.right = css({ textAlign: 'right' });
styles.txt.uppercase = css({ textTransform: 'uppercase' });

styles.bg.coverCenter = css({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const buttons = {
  feature: combine(
    css({
      background: `linear-gradient(to right, ${palette.purple} 0%, ${
        palette.blue
        } 100%)`,
    }),
    styles.txt.white
  ),
  primary: combine(styles.bg.blue, styles.txt.white),
  secondary: combine(styles.bg.green, styles.txt.white),
  social: combine(
    css({ backgroundColor: 'rgba(255, 255, 255, 0.07)' }),
    styles.txt.lightGrey
  ),
  white: combine(
    styles.bg.white,
    styles.txt.purple,
    css({ boxShadow: '0 0 2rem rgba(0,0,0,0.2)' })
  ),
};

const flex = {
  row: css({ display: 'flex', flexDirection: 'row' }),
  rowReverse: css({ display: 'flex', flexDirection: 'row-reverse' }),
  col: css({ display: 'flex', flexDirection: 'column' }),
  jcc: css({ justifyContent: 'center' }),
  jcsa: css({ justifyContent: 'space-around' }),
  jcse: css({ justifyContent: 'space-evenly' }),
  jcsb: css({ justifyContent: 'space-between' }),
  aic: css({ alignItems: 'center' }),
  asc: css({ alignSelf: 'center' }),
  ass: css({ alignSelf: 'stretch' }),
  wrap: css({ flexWrap: 'wrap' }),
  0: css({ flex: '0 !important' }),
  1: css({ flex: 1 }),
  2: css({ flex: 2 }),
  3: css({ flex: 3 }),
  4: css({ flex: 4 }),
  autoOnly: css({ flex: '0 0 auto !important' }),
};

const lists = {
  unformatted: css({
    listStyle: 'none',
    margin: 0,
  }),
};

const txt = styles.txt;
const bg = styles.bg;
const border = styles.border;

export {
  buttons,
  palette,
  css,
  combine,
  smallerThan,
  flex,
  lists,
  txt,
  bg,
  border,
};
