import React from 'react';
import { Link } from 'gatsby';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import ActionButton from '../ActionButton/ActionButton';
// import SubscribeInput from '../SubscribeInput/SubscribeInput';

import { bg, txt, border, combine, flex } from '../../style/utils';
import styles from './Footer.module.scss';

import logo from './footer-logo.png';

const footerLinkStructure = [
  {
    title: 'Flare',
    content: [
      { text: 'Product', location: '/flare' },
      { text: 'Integrations', location: '/integrations' },
      { text: 'Case Study', location: '/case-studies/vodafone' },
      { text: 'Request a trial', location: '/contact?type=trial' },
    ],
  },
  {
    title: 'Company',
    content: [
      { text: 'About', location: '/about-us' },
      // { text: 'Blog', location: '/blog' },
      { text: 'Contact us', location: '/contact' },
    ],
  },
  // {
  //   title: 'Subscribe',
  //   content: [
  //     //
  //     { dom: <SubscribeInput className={styles.subscribeInput} /> },
  //   ],
  // },
];

const Footer = () => (
  <ContentWrapper backgroundClasses={bg.darkPurple}>
    <footer className={styles.wrapper}>
      <div className={styles.topRow}>
        <div className={styles.logoSocialLinkContainer}>
          <Link to="/">
            <img src={logo} alt="Pixelnebula Logo" />
          </Link>
          <ul className={styles.socialButtonContainer}>
            <li>
              <ActionButton
                link="https://www.facebook.com/pixelnebula.limited/"
                icon="facebook"
                variant="social"
              />
            </li>
            <li>
              <ActionButton
                link="https://twitter.com/pixelnebula"
                icon="twitter"
                variant="social"
              />
            </li>
            <li>
              <ActionButton
                link="https://www.linkedin.com/company/pixelnebula/"
                icon="linkedin"
                variant="social"
              />
            </li>
          </ul>
        </div>

        <div className={styles.linkColumnContainer}>
          {footerLinkStructure.map(column => (
            <div key={column.title} className={styles.footerCol}>
              <h3>{column.title}</h3>
              <ul>
                {column.content.map((contentItem, i) => (
                  <li key={i}>
                    {contentItem.dom ? (
                      contentItem.dom
                    ) : (
                      <Link
                        to={contentItem.location}
                        className={combine(txt.footerGrey, styles.link)}
                      >
                        {contentItem.text}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <aside
        className={combine(
          txt.footerGrey,
          border.footerGrey,
          styles.admin,
          flex.col
        )}
      >
        <div className={flex.col}>
          <div className={combine(flex.row, flex.jcsb, styles.colMobile)}>
            <span>
              &copy; 2022 Pixelnebula Limited. &nbsp;All rights reserved
            </span>
            <span>
              <Link to="/terms">Terms of use</Link>
              <span>&nbsp;|&nbsp;</span>
              <Link to="/privacy">Privacy Policy</Link>
            </span>
          </div>
          <span className={combine(txt.center, styles.legal, flex.asc)}>
            Pixelnebula Limited is registered in England and Wales, Company No.
            08706717 <br />
            Registered office: Boundary House (Hubspace), Cricket Field Road,
            Uxbridge, UB8 1QG, United Kingdom
          </span>
        </div>
      </aside>
    </footer>
  </ContentWrapper>
);

export default Footer;
