import React from 'react';

// NOTE: this will change to push in gatsby 2.
import { navigate } from 'gatsby';

// Import icons.
import contactIcon from '../../../assets/icons/button-contact.png';
import trialIcon from '../../../assets/icons/button-trial.png';
import facebookIcon from '../../../assets/icons/button-facebook.png';
import linkedinIcon from '../../../assets/icons/button-linkedin.png';
import twitterIcon from '../../../assets/icons/button-twitter.png';
import readMoreIcon from '../../../assets/icons/button-readmore.png';

import { combine, buttons } from '../../style/utils';
import styles from './ActionButton.module.scss';

const iconMap = {
  contact: contactIcon,
  trial: trialIcon,
  facebook: facebookIcon,
  twitter: twitterIcon,
  linkedin: linkedinIcon,
  readmore: readMoreIcon,
};

class ActionButton extends React.Component {
  constructor(props) {
    super(props);
  }

  navigate() {
    if (!this.props.link) return;
    if (this.props.link.startsWith('http')) {
      // external link.
      window.location.href = this.props.link;
    } else {
      navigate(this.props.link);
    }
  }

  render() {
    let buttonStyles = combine(
      this.props.className,
      styles.actionButton,
      buttons[this.props.variant],
      this.props.icon ? styles.withIcon : null,
      this.props.icon && !this.props.children ? styles.withoutText : null
    );
    if (this.props.modifiers) {
      this.props.modifiers.forEach(
        mod => (buttonStyles += ` ${styles[mod] || ''}`)
      );
    }
    // Add classes to icons to allow for custom positioning.
    let iconClasses = styles.icon;
    if (this.props.icon)
      iconClasses += ' ' + styles[`icon__${this.props.icon}`];
    return (
      <button
        className={buttonStyles}
        onClick={
          this.props.onClick ? this.props.onClick : this.navigate.bind(this)
        }
      >
        {this.props.icon ? (
          <img
            src={iconMap[this.props.icon]}
            className={iconClasses}
            alt={`${this.props.icon} icon`}
          />
        ) : null}
        <span className={styles.text}>{this.props.children}</span>
      </button>
    );
  }
}

export default ActionButton;
