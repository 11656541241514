import React from 'react';
import debounce from 'lodash.debounce';

const breakpoints = {
  mobile: 1280,
};

class BreakPointWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsiveClasses: this.getClasses(),
    };
  }

  componentDidMount() {
    this.listener = debounce(this.updateState.bind(this), 50);
    window.addEventListener('resize', this.listener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.listener);
  }

  getClasses() {
    if (typeof window === 'undefined') return; // for build.
    return window.innerWidth <= breakpoints.mobile ? 'mobile' : null;
  }

  updateState() {
    this.setState({
      responsiveClasses: this.getClasses(),
    });
  }

  render() {
    return (
      <div
        style={{ overflowX: 'hidden' }}
        className={this.state.responsiveClasses}
      >
        {this.props.children}
      </div>
    );
  }
}

export default BreakPointWrapper;
