import React from 'react';

class StyleWithScroll extends React.Component {
  constructor(props) {
    super(props);
    // TODO: cross-browser prefix?
    this.state = {
      currentStyle: this.getStyle(),
    };
  }

  componentDidMount() {
    this.raf = window.requestAnimationFrame.bind(window);
    this.listener = this.onScroll.bind(this);
    window.addEventListener('scroll', this.listener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listener);
  }

  onScroll() {
    if (this.currentAFRequest) window.cancelAnimationFrame(this.request);
    this.request = this.raf(() => {
      this.setState({
        currentStyle: this.getStyle(),
      });
    });
  }

  getStyle() {
    if (typeof window === 'undefined') return;
    const result = {};
    Object.keys(this.props.styleChanges).forEach(key => {
      let {
        maxWidth,
        scrollRange: [rangeMin, rangeMax],
        change: {
          unit,
          values: [startVal, endVal],
        },
      } = this.props.styleChanges[key];
      // Return early if this rule doesn't apply at this window size.
      if (maxWidth && window.innerWidth > maxWidth) return;
      // Anchor the current scroll position so that it is within the range
      let currentPos = Math.min(Math.max(window.scrollY, rangeMin), rangeMax);
      let currentFraction = (currentPos - rangeMin) / (rangeMax - rangeMin);
      let currentValue = startVal + currentFraction * (endVal - startVal);
      result[key] =
        typeof unit === 'function'
          ? unit(currentValue)
          : `${currentValue}${unit}`;
    });
    return result;
  }

  render() {
    return (
      <div className={this.props.className} style={this.state.currentStyle}>
        {this.props.children}
      </div>
    );
  }
}

export default StyleWithScroll;
