import React from 'react';
import { Link } from 'gatsby';

import { combine, css, palette, smallerThan, txt } from '../../style/utils';

import MenuButton from '../MenuButton/MenuButton';
import ActionButton from '../ActionButton/ActionButton';
import StyleWithScroll from '../StyleWithScroll/StyleWithScroll';
import styles from './NavBar.module.scss';

const navItems = [
  { label: 'Home', destination: '/' },
  { label: 'About', destination: '/about-us' },
  { label: 'Product', destination: '/flare' },
  { label: 'Case Study', destination: '/case-studies/vodafone' },
  { label: 'Sectors', destination: '/sectors' },
  { label: 'Integrations', destination: '/integrations' },
  // { label: 'Blog', destination: '/blog' },
];

// NOTE: duplicated in style module, change in both places.
const menuBreakpoint = '1280px';

// The css section of these classes gives the underline on the link its gradient
const linkClasses = combine(
  css({
    '::before': {
      background: `linear-gradient(to right, ${palette.purple} 0%, ${
        palette.blue
        } 100%)`,
    },
  }),
  styles.navLink
);

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', () => this.closeMenu());
    document.addEventListener('touchmove', () => this.closeMenu());
  }

  toggleMenu() {
    if (this.ignoreEvents) return;
    // This applies the transition function so that we can prevent the menu
    // flashing up on first load of a mobile, otherwise it transitions out as
    // the media query is applied.
    if (!this.hasBeenOpened) this.hasBeenOpened = true;
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
    this.ignoreEventsForABit();
  }

  ignoreEventsForABit() {
    this.ignoreEvents = true;
    setTimeout(() => (this.ignoreEvents = false), 50);
  }

  closeMenu() {
    if (this.ignoreEvents) return;
    if (!this.state.menuOpen) return;
    this.setState({
      menuOpen: false,
    });
    this.ignoreEventsForABit();
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <StyleWithScroll
          className={combine(
            this.hasBeenOpened ? styles.transition : null,
            styles.navWrapper,
            this.state.menuOpen ? styles.open : null
          )}
          styleChanges={{
            transform: {
              maxWidth: 1280,
              scrollRange: [0, 88],
              change: {
                unit: v => `translateY(${v}px)`,
                values: [0, -22],
              },
            },
          }}
        >
          <nav
            className={combine(
              styles.nav,
              txt.darkGrey,
              smallerThan(menuBreakpoint, {
                backgroundColor: palette.purple,
                color: palette.white,
              })
            )}
          >
            <div className={combine(styles.linkContainer)}>
              {navItems.map((navItem, i) => (
                <Link
                  to={navItem.destination}
                  className={linkClasses}
                  activeClassName={styles.activeLink}
                  key={i}
                >
                  <h3 className={styles.navText}>{navItem.label}</h3>
                </Link>
              ))}
            </div>
            <div className={styles.buttonContainer}>
              <ActionButton
                link="/contact"
                icon="contact"
                className={styles.button}
                variant="primary"
              >
                Contact Us
              </ActionButton>
              <ActionButton
                link="/contact?type=trial"
                icon="trial"
                className={styles.button}
                variant="secondary"
              >
                Request a Trial
              </ActionButton>
            </div>
          </nav>
        </StyleWithScroll>
        <StyleWithScroll
          className={styles.menuButton}
          styleChanges={{
            transform: {
              maxWidth: 1280,
              scrollRange: [0, 88],
              change: {
                unit: v => `scale(${v})`,
                values: [1, 0.75],
              },
            },
          }}
        >
          <MenuButton
            menuOpen={this.state.menuOpen}
            onClick={this.toggleMenu.bind(this)}
          />
        </StyleWithScroll>
      </div>
    );
  }
}

export default NavBar;
