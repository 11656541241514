import React from 'react';
import { Link } from 'gatsby';

import headerLogo from './header-logo.png';
import headerStyle from './Header.module.scss';
import NavBar from '../NavBar/NavBar';
import StyleWithScroll from '../StyleWithScroll/StyleWithScroll';

const Header = () => (
  <div className={headerStyle.wrapper}>
    <StyleWithScroll
      className={headerStyle.container}
      styleChanges={{
        backgroundColor: {
          maxWidth: 1280,
          scrollRange: [50, 88],
          change: {
            unit: v => `rgba(255, 255, 255, ${v})`,
            values: [0, 1],
          },
        },
        height: {
          maxWidth: 1280,
          scrollRange: [0, 88],
          change: {
            unit: v => `calc(var(--rhythm-unit) * ${v})`,
            values: [4, 3],
          },
        },
      }}
    >
      <StyleWithScroll
        styleChanges={{
          transform: {
            maxWidth: 1280,
            scrollRange: [0, 88],
            change: {
              unit: v => `scale(${v})`,
              values: [1, 0.75],
            },
          },
        }}
      >
        <StyleWithScroll
          styleChanges={{
            transform: {
              maxWidth: 1280,
              scrollRange: [0, 88],
              change: {
                unit: v => `translate(-${v * 2}px, -${v}px)`,
                values: [0, 19],
              },
            },
          }}
        >
          <Link to="/">
            <img
              className={headerStyle.logo}
              src={headerLogo}
              alt="Pixelnebula logo"
            />
          </Link>
        </StyleWithScroll>
      </StyleWithScroll>
      <NavBar />
    </StyleWithScroll>
  </div>
);
export default Header;
