import get from 'lodash.get';

/**
 * Finds the first node within `data[dataProp]` whose property located at
 * `criteriaPath` matches the value specified as `criteriaVal`. Assumes normal
 * graphQL data structure with `edges` and `node` properties.
 * @param {object} data GraphQL query result data.
 * @param {string} [dataProp] The property name in the data to search within
 * @param {string} criteriaPath The path to the criteria val you're looking for
 * @param {*} criteriaVal The value you're looking for.
 * @returns {object} The matching node.
 */
export function findNode(data, dataProp, criteriaPath, val) {
  let dataSet;
  if (!data) return;
  if (dataProp) {
    if (!data[dataProp]) return;
    dataSet = data[dataProp].edges;
  } else {
    dataSet = data.edges;
  }
  for (let i = 0; i <= dataSet.length; i++) {
    const item = dataSet[i];
    if (get(item, `node.${criteriaPath}`) === val) {
      return item.node;
    }
  }
}

export function getImage(data, val) {
  return (
    findNode(data, null, 'fluid.name', val) ||
    findNode(data, null, 'fixed.name', val)
  );
}
