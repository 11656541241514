import Typography from 'typography';
import { polyfill } from 'es6-object-assign';
import { palette } from '../style/utils';
import sInput from '../components/SubscribeInput/SubscribeInput.module.scss';
import footer from '../components/Footer/Footer.module.scss';
import benefitsGrid from '../components/BenefitsGrid/BenefitsGrid.module.scss';
import aboutUs from '../pages/about-us.module.scss';
import sectors from '../pages/sectors.module.scss';
import caseStudy from '../pages/case-studies/case-study.module.scss';

// Needed for IE11 because typography tries to use Object.assign.
polyfill();

const typography = new Typography({
  title: 'Pixelnebula public site',
  includeNormalize: true,
  baseFontSize: '14px',
  baseLineHeight: 22 / 14,
  scaleRatio: 2,
  googleFonts: [
    {
      name: 'Raleway',
      styles: ['200', '400', '500', '600', '700'],
    },
    {
      name: 'Lato',
      styles: ['300', '400', '400i', '500', '900'],
    },
  ],
  // TODO: add fallbacks
  headerFontFamily: ['Raleway'],
  bodyFontFamily: ['Lato'],
  headerWeight: 500,
  headerColor: palette.darkGrey,
  bodyWeight: 500,
  bold: 900,

  /* eslint-disable no-unused-vars */
  overrideStyles: ({ adjustFontSizeTo, rhythm }, options, styles) => {
    return {
      h1: {
        // pairs with h2
        ...adjustFontSizeTo('6rem'),
      },
      '.mobile h1': {
        // pairs with h2
        ...adjustFontSizeTo('4.5rem'),
      },
      h2: {
        ...adjustFontSizeTo('2.5rem'),
        fontFamily: 'Lato',
      },
      h3: {
        // pairs with h4
        ...adjustFontSizeTo('4.7rem'),
        color: palette.purple,
      },
      h4: {
        ...adjustFontSizeTo('2.42rem'),
        fontWeight: 400,
        letterSpacing: '0.03em',
      },
      h5: {
        // pairs with h5.
        color: palette.purple,
        ...adjustFontSizeTo('3rem'),
        letterSpacing: '0.03em',
        fontWeight: 400,
      },
      h6: {
        // paris with paragraphs.
        color: palette.blue,
        ...adjustFontSizeTo('1.6rem'),
        marginBottom: rhythm(0.5),
      },
      [`.${aboutUs.teamMember} h5`]: {
        ...adjustFontSizeTo('1.2rem'),
        marginBottom: 0,
      },
      [`.${aboutUs.teamMember} h6`]: {
        ...adjustFontSizeTo('1rem'),
      },
      [`.${benefitsGrid.container} h6`]: {
        ...adjustFontSizeTo('1.2rem'),
      },
      [`.${benefitsGrid.container} p`]: {
        ...adjustFontSizeTo('1rem'),
        marginBottom: 0,
      },
      p: {
        ...adjustFontSizeTo('1.15rem'),
        lineHeight: rhythm(1.5),
      },
      li: {
        ...adjustFontSizeTo('1.15rem'),
        lineHeight: rhythm(1.5),
        marginBottom: 0,
      },
      '.mobile p, .mobile li': {
        ...adjustFontSizeTo('1.3rem'),
        lineHeight: rhythm(1.25),
      },
      '.mobile li': {
        marginBottom: rhythm(0.25),
      },
      'p.feature': {
        ...adjustFontSizeTo('1rem'),
      },
      '.mobile p.feature': {
        ...adjustFontSizeTo('1.3rem'),
        lineHeight: rhythm(1.25),
      },
      [`p.${caseStudy.factDetail}`]: {
        fontFamily: 'Raleway',
        ...adjustFontSizeTo('1.5rem'),
      },
      [`.mobile .${sectors.sectorText} p, .mobile .${
        sectors.sectorText
        } li, .mobile .${sectors.sectorText} a`]: {
        ...adjustFontSizeTo('1.3rem'),
        lineHeight: rhythm(1.25),
      },
      // Navigation links
      'nav h3': {
        color: 'inherit',
        ...adjustFontSizeTo('1rem'),
        textTransform: 'uppercase',
        fontWeight: 600,
      },
      '.mobile nav h3': {
        color: 'inherit',
        ...adjustFontSizeTo('1.5rem'),
        textTransform: 'none',
        fontWeight: 400,
      },
      'footer h3': {
        fontFamily: 'Lato',
        color: palette.footerHeaderGrey,
        ...adjustFontSizeTo('1.3rem'),
        textTransform: 'uppercase',
        fontWeight: 500,
      },
      'button span': {
        ...adjustFontSizeTo('0.87055rem'),
        fontFamily: 'Lato',
        textTransform: 'uppercase',
        marginBottom: 0,
        letterSpacing: '0.05em',
        transform: 'scaleX(0.9)',
        fontWeight: 300,
        color: 'inherit',
      },
      '.mobile nav button span': {
        ...adjustFontSizeTo('1.2rem'),
        padding: rhythm(0.25),
      },
      [`.${sInput.input}, .${sInput.input}::placeholder`]: {
        ...adjustFontSizeTo('1rem'),
        color: palette.footerHeaderGrey,
        fontWeight: 500,
      },
      [`.${footer.link}`]: {
        ...adjustFontSizeTo('0.9rem'),
        textTransform: 'uppercase',
        color: palette.footerGrey,
        fontWeight: 500,
        ':hover': {
          color: palette.white,
        },
      },
      [`.mobile .${footer.link}`]: {
        ...adjustFontSizeTo('1.1rem'),
        marginBottom: 0,
        paddingBottom: rhythm(0.5),
      },
      [`.${footer.link}:hover`]: {
        color: palette.white,
      },
      a: {
        textDecoration: 'none',
        color: 'inherit',
      },
      'li a': {
        color: palette.purple,
      },
      'footer aside': {
        fontFamily: 'Lato',
        // opacity: 0.75,
        fontWeight: 500,
        textTransform: 'uppercase',
        ...adjustFontSizeTo('0.9rem'),
      },
    };
  },
});

const { rhythm, scale } = typography;
export { rhythm, scale, typography as default };
