/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import 'babel-polyfill';
const outdatedBrowser = require('outdated-browser-rework');

module.exports.onClientEntry = function () {
  var outdatedDiv = document.createElement('div');
  outdatedDiv.id = 'outdated';
  document.body.appendChild(outdatedDiv);
  outdatedBrowser({
    browserSupport: {
      'Chrome': 57, // Includes Chrome for mobile devices
      'Edge': true, // specific version number didn't seem to be working.
      'Safari': 10,
      'Mobile Safari': 10,
      'Firefox': 50,
      'Opera': 50,
      'Vivaldi': 1,
      'IE': false
    },
    requireChromeOnAndroid: false,
    isUnknownBrowserOK: false,
    messages: {
      en: {
        outOfDate: "You're using an outdated browser!",
        update: {
          web: 'This will result in a degraded experience on this and other websites, and could pose security risks',
          googlePlay: 'Please install Chrome from Google Play',
          appStore: 'Please update iOS from the Settings App',
        },
        url: 'http://outdatedbrowser.com/',
        callToAction: 'Find out how to update',
        close: 'Close',
      }
    }
  });
}
