import React from 'react';
import Img from 'gatsby-image';

import { BackgroundImagesContext } from '../../layouts/index';
import styles from './ContentWrapper.module.scss';
import { findNode } from '../../utils/queryUtils';
import { css, combine, txt, bg } from '../../style/utils';

const variants = {
  dark: {
    name: 'wrapper-bg-dark.png',
  },
  light: {
    name: 'wrapper-bg-light.jpg',
  },
  fullWidthMobile: styles.fullWidthMobile,
  noVerticalPaddingMobile: styles.noVerticalPaddingMobile,
  reducedPadding: styles.reducedPadding,
  narrow: styles.narrow,
};

const ContentWrapper = ({
  children,
  variant,
  backgroundClasses,
  className,
}) => {
  let bgClass, image;
  if (variant) {
    if (!Array.isArray(variant)) variant = [variant];
    // This filters out variants that supply an image to use as a bg.
    bgClass = variant.map(
      v => (typeof variants[v] === 'string' ? variants[v] : null)
    );
    image = variant.map(
      v => (typeof variants[v] === 'object' ? variants[v] : null)
    )[0];
    bgClass = combine(...bgClass);
  }

  return (
    <BackgroundImagesContext.Consumer>
      {data => {
        if (image) {
          image = findNode(data, null, 'fluid.name', image.name);
        }
        return (
          <div
            className={combine(
              className,
              styles.backgroundWrapper,
              backgroundClasses,
              bgClass,
              variant // for easy typography modifications based on variant.
            )}
          >
            {image ? (
              <Img
                fluid={image.fluid}
                className={styles.image}
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            ) : null}
            <div className="contentWidth">{children}</div>
          </div>
        );
      }}
    </BackgroundImagesContext.Consumer>
  );
};

export default ContentWrapper;
